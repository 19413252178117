import { useState, useEffect } from "react";
import { CaretDoubleLeft } from "phosphor-react";
import ComparisonFilters from "./ComparisonFilters";
import ComparisonTable from "./ComparisonTable";
import ComparisonScatter from "./ComparisonScatter";
import ComparisonHistogram from "./ComparisonHistogram";
import MosaicPanel from "../../common/MosaicPanel";

function Comparison() {
  const [expanded, setExpanded] = useState(false);

  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "column",
      first: "table",
      second: {
        direction: "row",
        first: "scatter",
        second: "bars",
      },
      splitPercentage: 50,
    },
  });

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  // TODO: get loading from session provider
  const ELEMENT_MAP = {
    table: false ? (
      <div className="tile-loader-container">
        <div className="tile-loader"></div>
      </div>
    ) : (
      <ComparisonTable />
    ),
    scatter: false ? (
      <div className="tile-loader-container">
        <div className="tile-loader"></div>
      </div>
    ) : (
      <ComparisonScatter />
    ),
    bars:
      false ? (
        <div className="tile-loader-container">
          <div className="tile-loader"></div>
        </div>
      ) : (
        <ComparisonHistogram />
      ),
  };

  return (
    <div className="project-tab" id="comparison-section">
      <div id="expression-filters" className={expanded ? "minimized" : ""}>
          <ComparisonFilters />
        </div>
      <div id="comparison-right">
        <MosaicPanel
          mosaicId="comparison"
          elementMap={ELEMENT_MAP}
          tilesLayout={tilesLayout}
          onChangeLayout={handleChangeMosaicLayout}
        />
        <div id="expand-button-container">
          <button
            id="expand-button"
            onClick={() => setExpanded(!expanded)}
            className={expanded ? "expanded" : ""}
          >
            <CaretDoubleLeft size={16} weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Comparison;
