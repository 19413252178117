import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Trash, DownloadSimple, UploadSimple } from "phosphor-react";
import { useGetDatasets } from "../hooks/datasets";
import Data from "src/components/datasets/Data";
import Alert from "src/components/Alert";
import { Grid, Alert as MuiAlert } from "@mui/material";
import UploadDatasetDialog from "src/components/datasets/UploadDatasetDialog";
import DeleteDatasetDialog from "src/components/datasets/DeleteDatasetDialog";

function Datasets() {
  let params = useParams();
  const { data: datasets, isLoading } = useGetDatasets(params.id);

  const [filteredDatasets, setFilteredDatasets] = useState([]);
  const [search, setSearch] = useState("");
  const [uploadDatasetOpen, setUploadDatasetOpen] = useState(false);
  const [delimiter, setDelimiter] = useState(",");
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const deleteDatasetRequest = (dataset) => {
    setSelectedDataset(dataset);
    setDeleteDialogOpen(true);
  };

  const updateSearch = useCallback(
    (newSearch) => {
      setSearch(newSearch);
      setFilteredDatasets(
        datasets.data.filter((dataset) =>
          dataset.name.toLowerCase().includes(newSearch.toLowerCase())
        )
      );
    },
    [setSearch, setFilteredDatasets, datasets?.data]
  );

  useEffect(() => {
    if (datasets?.data) {
      if (datasets.data.length > 0) {
        setDelimiter(datasets.data[0].delimiter);
      }
      updateSearch("");
    }
  }, [datasets, updateSearch]);

  return (
    <div className="project-tab" id="datasets-section">
      <Grid container spacing={2}>
        {filteredDatasets.length === 0 && !isLoading && (
          <Grid item xs={12}>
            <Alert
              type="info"
              message="No dataset found. Please create a dataset first."
            />
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <div id="datasets-left">
            <div id="dataset-upload-container">
              <input
                type="text"
                id="dataset-search"
                placeholder="Search a dataset"
                value={search}
                onChange={(e) => updateSearch(e.target.value)}
              ></input>
              <button
                id="upload-dataset-button"
                className="icon-button submit-button"
                type="button"
                onClick={() => setUploadDatasetOpen(true)}
              >
                <UploadSimple size={18} color="#ffffff" weight="bold" />
                <span>Upload</span>
              </button>
            </div>
            <div id="datasets-table">
              <div className="table-top">
                <span className="name">NAME</span>
                <span className="download"></span>
                <span className="trash"></span>
              </div>
              <div className="table-body">
                {filteredDatasets &&
                  filteredDatasets.map((dataset, index) => {
                    const isSelected =
                      selectedDataset && dataset.id === selectedDataset.id;
                    return (
                      <li
                        key={index}
                        className={`table-entry${
                          isSelected ? " selected" : ""
                        }`}
                      >
                        <div
                          className="info"
                          onClick={() => {
                            setSelectedDataset(dataset);
                            setDelimiter(dataset.delimiter);
                          }}
                        >
                          <span className="name">{dataset.name}</span>
                        </div>
                        {isSelected && (
                          <>
                            <span className="download">
                              <a
                                href={`/api/projects/${params.id}/datasets/${dataset.id}/download`}
                              >
                                <DownloadSimple size={20} />
                              </a>
                            </span>
                            <span
                              className="trash"
                              onClick={() => deleteDatasetRequest(dataset)}
                            >
                              <Trash size={20} color="#ad1b00" />
                            </span>
                          </>
                        )}
                      </li>
                    );
                  })}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          {!selectedDataset &&
            filteredDatasets.length > 0 &&
            !selectedDataset && (
              <MuiAlert severity='info' >Select a dataset to see details.</MuiAlert>
            )}
          {selectedDataset && (
            <Data
              datasetId={selectedDataset?.id}
              delimiter={delimiter}
              fileName={selectedDataset?.name}
            />
          )}
        </Grid>
      </Grid>
      {uploadDatasetOpen && (
        <UploadDatasetDialog
          open={uploadDatasetOpen}
          onClose={() => setUploadDatasetOpen(false)}
        />
      )}
      {deleteDialogOpen && selectedDataset && (
        <DeleteDatasetDialog
          open={true}
          dataset={selectedDataset}
          onDelete={() => setSelectedDataset(null)}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
    </div>
  );
}

export default Datasets;
