import { useState } from "react";
import {  CaretDoubleLeft } from "phosphor-react";
import {
  SessionTypes,
  SessionStatus,
} from "src/utils/types";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import MosaicPanelEmptyMsg from "src/components/common/empty-message/MosaicPanelEmptyMsg";
import MosaicPanel from "src/components/common/MosaicPanel";
import TrainingLogs from "./TrainingLogs";
import GenerationsTable from "./GenerationsTable";
import MetricsEvolution from "./MetricsEvolution";
import TrainingSidebar from "./TrainingSidebar";

const Training = () => {
  const {
    session,
    sessionStatus,
    isTestSession,
    generationResults,
    logs,
  } = useMultiTreeSession();

  const [expanded, setExpanded] = useState(false);
  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "column",
      first: Boolean(session?.properties?.commandType === SessionTypes.TEST)
        ? "test_results"
        : "generations",
      second: {
        direction: "row",
        first: "metrics",
        second: "logs",
      },
      splitPercentage: 50,
    },
  });

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const isFailedOrFinished =
    sessionStatus === SessionStatus.FAILED ||
    sessionStatus === SessionStatus.FINISHED;

  const elementMap = {
    generations:
      !isTestSession && generationResults?.length > 0 ? (
        <GenerationsTable type="train" />
      ) : (
        isFailedOrFinished && (
          <MosaicPanelEmptyMsg
            title={"No generations found"}
            desc={"make sure your algorithm outputs generations.csv"}
          />
        )
      ),
    test_results: isTestSession && generationResults?.length > 0 && (
      <GenerationsTable type="test" />
    ),
    metrics: generationResults?.length > 0 && (
      // <MetricsEvolution type={isTestSession ? "test" : "train"} />
      <p>metrics</p>
    ),
    logs: logs && <TrainingLogs logs={logs} />,
  };

  return (
    <div className="model-tab" id="model-training-section">
      <div id="training-left" className={expanded ? "minimized" : ""}>
        <TrainingSidebar />
      </div>

      <div id="training-right">
        <MosaicPanel
          tilesLayout={tilesLayout}
          onChangeLayout={handleChangeMosaicLayout}
          mosaicId="training"
          elementMap={elementMap}
        />
        <div id="expand-button-container">
          <button
            id="expand-button"
            onClick={() => setExpanded(!expanded)}
            className={expanded ? "expanded" : ""}
          >
            <CaretDoubleLeft size={16} weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Training;
