import uniqid from "uniqid";
import Xarrow from "react-xarrows";

const ExpParserNodeTypes = {
  FUNCTION: "FunctionNode",
  CONSTANT: "ConstantNode",
  SYMBOL: "SymbolNode",
  PARANTHESIS: "ParenthesisNode",
  OPERATOR: "OperatorNode",
};

export const buildTree = (node, arrows) => {
  if (node.parent) {
    arrows.push(
      <Xarrow
        key={`${node.id}-${node.parent}`}
        start={node.parent}
        end={node.id}
        endAnchor="top"
        startAnchor="bottom"
        showHead={false}
        gridBreak="100%-50"
        curveness={0.3}
        strokeWidth={2}
        color="#8a99a8"
      />,
    );
  }

  return (
    <div
      key={node.id}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        alignItems: "center",
      }}
    >
      <li className="node" key={node.id} id={node.id}>
        <span
          id={`${node.id}-content`}
          className={`node-content ${node.childrens ? "combiner-node" : ""}`}
        >
          <span className="node-value">{node.title}</span>
        </span>
      </li>
      {node.childrens ? (
        <div
          style={{ display: "flex", justifyContent: "center", gap: ".5rem" }}
        >
          {node.childrens.map((c) => buildTree(c, arrows))}
        </div>
      ) : null}
    </div>
  );
};

export const mapParsedExpression = (obj, allNodes, depth, parent) => {
  const objType = obj.type;
  const objIsArray = Array.isArray(obj);

  if (objIsArray) {
    obj.forEach((item) => {
      mapParsedExpression(item, allNodes, depth, parent);
    });
  } else if (objType === ExpParserNodeTypes.FUNCTION) {
    const nodeName = obj.fn.name;
    const nodeId = `${nodeName}-${depth}-${uniqid()}`;
    const node = {
      title: nodeName,
      depth: depth,
      id: nodeId,
      type: ExpParserNodeTypes.FUNCTION,
      parent,
      childrens: [],
    };
    allNodes.push(node);
    depth = depth + 1;
    mapParsedExpression(obj.args, node.childrens, depth, nodeId);
  } else if (objType === ExpParserNodeTypes.CONSTANT) {
    const nodeName = obj.value;
    const nodeId = `${nodeName}-${depth}-${uniqid()}`;
    const node = {
      title: nodeName,
      depth: depth,
      id: nodeId,
      type: ExpParserNodeTypes.CONSTANT,
      parent,
    };
    allNodes.push(node);
  } else if (objType === ExpParserNodeTypes.SYMBOL) {
    const nodeName = obj.name;
    const nodeId = `${nodeName}-${depth}-${uniqid()}`;
    const node = {
      title: nodeName,
      depth: depth,
      id: nodeId,
      type: ExpParserNodeTypes.SYMBOL,
      parent,
    };
    allNodes.push(node);
  } else if (objType === ExpParserNodeTypes.PARANTHESIS) {
    const isConstantNode = Boolean(obj.content.args) ? false : true;
    if (isConstantNode) {
      mapParsedExpression(obj.content, allNodes, depth, parent);
      return;
    }

    const nodeName =
      typeof obj.content.fn === "string"
        ? obj.content.fn
        : obj.content.fn?.name;
    const nodeId = `${nodeName}-${depth}-${uniqid()}`;
    const node = {
      title: nodeName,
      depth: depth,
      id: nodeId,
      type: ExpParserNodeTypes.PARANTHESIS,
      parent,
      childrens: [],
    };
    allNodes.push(node);
    depth = depth + 1;
    if (obj.content.args) {
      obj.content.args.forEach((item) => {
        mapParsedExpression(item, node.childrens, depth, nodeId);
      });
    }
  } else if (objType === ExpParserNodeTypes.OPERATOR) {
    const nodeName = obj.fn;
    const nodeId = `${nodeName}-${depth}-${uniqid()}`;
    const node = {
      title: nodeName,
      depth: depth,
      id: nodeId,
      type: ExpParserNodeTypes.OPERATOR,
      parent,
      childrens: [],
    };
    allNodes.push(node);
    depth = depth + 1;
    if (obj.args) {
      obj.forEach((item) => {
        mapParsedExpression(item, node.childrens, depth, nodeId);
      });
    }
  } else {
    console.log("unknown node", obj);
  }
};


export const calcMetrics = (selectedTreeItem, combinedTreeList) => {
  // tree calc variables
  let fitnessMax = Number.MIN_SAFE_INTEGER;
  let fitnessMin = Number.MAX_SAFE_INTEGER;
  let sizeMax = Number.MIN_SAFE_INTEGER;
  let sizeMin = Number.MAX_SAFE_INTEGER;
  let fitnesTotal = 0;
  let sizeTotal = 0;
  let totalItemCount = 0;
  let treeCalculations = {
    averages: {},
    mins: {},
    maxes: {},
  };

  // model calc variables
  let modelFields = new Set(["fitness", "size"]);
  Object.keys(selectedTreeItem.modelOther || {}).forEach((key) =>
    modelFields.add(key)
  );
  let modelFieldValues = new Map();
  let modelIds = new Set();
  Array.from(modelFields).forEach((key) => {
    modelFieldValues.set(key, []);
  });
  let models = [];
  let modelCalculations = {
    averages: {},
    mins: {},
    maxes: {},
  };

  // iterate all tree items
  combinedTreeList.forEach((item) => {
    fitnesTotal += item.fitness;
    sizeTotal += item.size;
    totalItemCount += 1;
    if (item.fitness > fitnessMax) {
      fitnessMax = item.fitness;
    }
    if (item.fitness < fitnessMin) {
      fitnessMin = item.fitness;
    }
    if (item.size > sizeMax) {
      sizeMax = item.size;
    }
    if (item.size < sizeMin) {
      sizeMin = item.size;
    }

    // collect models data
    if (!modelIds.has(item.id)) {
      modelIds.add(item.id);
      let modelData = {
        fitness: item.modelFitness,
        size: item.modelSize,
      };
      Object.keys(selectedTreeItem.modelOther || {}).forEach((key) => {
        modelData[key] = item.modelOther[key];
      });
      models.push(modelData);
    }
  });

  const fitnessAverage = fitnesTotal / totalItemCount;
  const sizeAverage = sizeTotal / totalItemCount;

  treeCalculations.averages.fitness = fitnessAverage.toFixed(2);
  treeCalculations.averages.size = sizeAverage.toFixed(2);
  treeCalculations.maxes.fitness = fitnessMax.toFixed(2);
  treeCalculations.mins.fitness = fitnessMin.toFixed(2);
  treeCalculations.maxes.size = sizeMax.toFixed(2);
  treeCalculations.mins.size = sizeMin.toFixed(2);

  // fill model calculations map
  models.forEach((model) => {
    Object.keys(model).forEach((key) => {
      if (modelFieldValues.has(key)) {
        modelFieldValues.set(key, [...modelFieldValues.get(key), model[key]]);
      }
    });
  });

  // calc model fields
  Array.from(modelFieldValues).forEach(([key, val]) => {
    if (val.length > 0 && typeof val[0] === "number") {
      const metricAverage = val.reduce((acc, c) => acc + c, 0) / val.length;
      const metricMax = Math.max(...val);
      const metricMin = Math.min(...val);
      modelCalculations.averages[key] = metricAverage.toFixed(2);
      modelCalculations.maxes[key] = metricMax.toFixed(2);
      modelCalculations.mins[key] = metricMin.toFixed(2);
    }
  });

  return { treeCalculations, modelCalculations };
}