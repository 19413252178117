import { useState, useRef, useMemo, useEffect } from "react";
import TreeVisualizer from "./TreeVisualizer";
import ExpressisonVisualizer from "./ExpressisonVisualizer";
import MetricsVisualizer from "./MetricsVisualizer";
import MosaicPanel from "../../common/MosaicPanel";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { calcMetrics } from "./utils";
import { SliderButtonDirection } from "src/utils/types";

const FIRST_MODEL_INDEX = 1;
const FIRST_TREE_INDEX = 0;
const FIRST_GENERATION_INDEX = 0;

function Evaluation() {
  const { combinedTreeList } = useMultiTreeSession();
  const fullscreenRef = useRef(null);

  const [parsedExpTree, setParsedExpTree] = useState(null);
  const [parsingError, setParsingError] = useState(null);
  const [generationIndex, setGenerationIndex] = useState(
    FIRST_GENERATION_INDEX
  );
  const [modelIndex, setModelIndex] = useState(FIRST_MODEL_INDEX);
  const [treeIndex, setTreeIndex] = useState(FIRST_TREE_INDEX);
  const [selectedTreeItem, setSelectedTreeItem] = useState(null);

  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "row",
      first: {
        direction: "column",
        first: "expression",
        second: "metrics",
        splitPercentage: 55,
      },
      second: "tree",
      splitPercentage: 40,
    },
    windowsCount: 3,
    closedWindows: ["new", "bar chart"],
  });

  const treeItemsByModel = useMemo(() => {
    if (!combinedTreeList) {
      return;
    }

    let smallestModel = 0;
    let smallestGeneration = 0;
    let trees = [];

    if (!selectedTreeItem) {
      combinedTreeList.forEach((tree) => {
        if (tree.individual > smallestModel) {
          smallestModel = tree.individual;
        }
        if (tree.generation > smallestGeneration) {
          smallestGeneration = tree.generation;
        }
      });
      trees = combinedTreeList.filter(
        (item) =>
          String(item.generation) == String(smallestGeneration) &&
          String(item.individual) == String(smallestModel)
      );
      setGenerationIndex(smallestGeneration);
      setModelIndex(smallestModel);
    } else {
      trees = combinedTreeList.filter(
        (item) =>
          String(item.generation) == String(generationIndex) &&
          String(item.individual) == String(modelIndex)
      );
    }
    return trees || [];
  }, [modelIndex, generationIndex, combinedTreeList]);

  useEffect(() => {
    setSelectedTreeItem(
      treeItemsByModel.length > 0 ? treeItemsByModel[treeIndex] : null
    );
  }, [treeIndex, treeItemsByModel]);

  const metrics = useMemo(() => {
    if (selectedTreeItem) {
      return {
        tree: {
          fitness: selectedTreeItem.fitness,
          size: selectedTreeItem.size,
        },
        model: {
          fitness: selectedTreeItem.modelFitness,
          size: selectedTreeItem.modelSize,
          ...selectedTreeItem.modelOther,
        },
      };
    }
    return null;
  }, [selectedTreeItem]);

  const metricsStats = useMemo(() => {
    if (!combinedTreeList || !selectedTreeItem) {
      return null;
    }
    const { treeCalculations, modelCalculations } = calcMetrics(
      selectedTreeItem,
      combinedTreeList
    );
    return { tree: treeCalculations, model: modelCalculations };
  }, [combinedTreeList]);

  const findNextAvailableIndex = (activeIndex) => {
    // since there are multiple objects with same generation and individual because of multi tree
    // iterate one by one to find next different model

    if (combinedTreeList.length > activeIndex + 1) {
      const nextIndex = Number(activeIndex) + 1;
      const nextItem = combinedTreeList[nextIndex];
      if (
        nextItem.generation == generationIndex &&
        nextItem.individual == modelIndex
      ) {
        findNextAvailableIndex(nextIndex);
      } else {
        setGenerationIndex(nextItem.generation);
        setModelIndex(nextItem.individual);
        setTreeIndex(FIRST_TREE_INDEX);
      }
    } else {
      const firstItem = combinedTreeList[0];
      setGenerationIndex(firstItem.generation);
      setModelIndex(firstItem.individual);
      setTreeIndex(FIRST_TREE_INDEX);
    }
  };

  const findPrevAvailableIndex = (activeIndex) => {
    // since there are multiple objects with same generation and individual because of multi tree
    // iterate one by one to find prev different model

    if (activeIndex > 0) {
      const prevIndex = Number(activeIndex) - 1;
      const prevItem = combinedTreeList[prevIndex];
      if (
        prevItem.generation == generationIndex &&
        prevItem.individual == modelIndex
      ) {
        findPrevAvailableIndex(prevIndex);
      } else {
        setGenerationIndex(prevItem.generation);
        setModelIndex(prevItem.individual);
        setTreeIndex(FIRST_TREE_INDEX);
      }
    } else {
      const lastItem = combinedTreeList[combinedTreeList.length - 1];
      setGenerationIndex(lastItem.generation);
      setModelIndex(lastItem.individual);
      setTreeIndex(FIRST_TREE_INDEX);
    }
  };

  const changeExpressionIndex = (direction) => {
    if (!combinedTreeList || combinedTreeList.length === 0) return;
    let currentIndex = combinedTreeList.findIndex(
      (item) =>
        item.generation == generationIndex && item.individual == modelIndex
    );

    if (direction == SliderButtonDirection.NEXT) {
      findNextAvailableIndex(currentIndex);
    }
    if (direction == SliderButtonDirection.PREV) {
      findPrevAvailableIndex(currentIndex);
    }
  };

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const ELEMENT_MAP = {
    expression: selectedTreeItem && (
      <ExpressisonVisualizer
        treeItemsByModel={treeItemsByModel}
        individual={selectedTreeItem}
        parsingError={parsingError}
        setParsingError={setParsingError}
        setParsedExpTree={setParsedExpTree}
        onChangeTreeIndex={setTreeIndex}
        changeExpressionIndex={changeExpressionIndex}
      />
    ),
    metrics: metrics && metricsStats && (
      <MetricsVisualizer metrics={metrics} metricsStats={metricsStats} />
    ),
    tree: parsedExpTree && !parsingError && (
      <TreeVisualizer
        parsedExpTree={parsedExpTree}
        selectedTreeItem={selectedTreeItem}
      />
    ),
  };

  return (
    <div
      className="project-tab"
      id="model-evaluation-section"
      ref={fullscreenRef}
    >
      <MosaicPanel
        tilesLayout={tilesLayout}
        onChangeLayout={handleChangeMosaicLayout}
        mosaicId="evaluation"
        elementMap={ELEMENT_MAP}
      />
    </div>
  );
}

export default Evaluation;
